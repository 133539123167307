$avatar-size: 26px;
$avatar-size-with-remove: 14px;

@mixin avatarStyle($size) {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  vertical-align: middle;
  width: $size;
  height: $size;
}

.avatarStyle {
  @include avatarStyle($avatar-size);
  border-radius: 4px;
  position: relative;
  top: -2px;
}

.avatarStyleWithRemove {
  @include avatarStyle($avatar-size-with-remove);
  position: relative;
  top: -1px;
  border-radius: 1px;
}

.toolbar {
  margin-bottom: 15px;
  height: 34px;
}

/* FontAwesomeIcon */
.fa {
  font-size: 16px;
}

.modalLarge {
  width: 80%;
}

.modalHeader {
  font-weight: bold;
  font-size: 16
}

.receiptContent, .jsonData {
  max-height: calc(100% - 20px);
  overflow: auto;
}

.receiptContent {
  white-space: pre-wrap;
}

.warningMessage {
  color: #a94442
}

.accountType {
  :global(.Select-control) {
    overflow: visible;
  }
}

.campaignsFilter {
  :global(.Select-control) {
    overflow: visible;
  }
}

.serviceTextJson {
  margin-top: 10px;
  border: 0.5px gray solid;
  border-radius: 5px;
  display: block;
  padding: 10px;
  color: gray;
  font-weight: bolder;
  white-space: pre-wrap;
  max-height: 100%;
  overflow: auto;
}

.serviceTextPlain {
  margin-top: 10px;
  border: 0.5px gray solid;
  word-wrap: break-word;
  border-radius: 5px;
  display: block;
  padding: 10px;
  color: gray;
  font-weight: bolder;
}

.spinner {
  color: #a94442;
  position:absolute;
  top:45%;
  left:45%;
}

.autoTransfersModal {
  min-height: 120px;
}

.jsonData > ul{
  padding: 5px!important;
}

.highlightWDFields {
  background-color: #ffffcf!important;
}

.checkboxStyle {
  margin-top: 5px;
}

.checkboxLabelStyle {
  padding-left: 10px;
}

.scrollable {
  max-height: 200px;
  overflow-y: scroll;
}

.modal > div > div {
  max-height: inherit !important;
}

.smallModalContent > div > div {
  max-height: inherit;
}

.hiddenModal > div {
  display: none;
}
