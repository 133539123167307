.Select {
  &.is-disabled {
    .Select-control .Select-value {
      background-color: #eee;
    }
  }

  .Select-clear-zone {
    opacity: 0;
    transition: opacity 0.1s;
  }
  &:hover .Select-clear-zone {
    opacity: 1;
  }
}
.Select-control {
  height: 34px;
  font-size: 14px;
}

.Select-input {
  height: 32px;
}

.Select-active > div > div:first-of-type,
.form-group.has-success .Select-control {
  background-color: #e6fedb;
  border-color: #3c763d;
}

.form-group.has-error .Select-control {
  border-color: #a94442;
}

.form-group .Select-control:focus {
  border-color: rgb(102, 175, 233);
}

.form-group .is-open .Select-control {
  border-color: rgb(102, 175, 233);
}

.is-focused:not(.is-open) > .Select-control {
  box-shadow: none;
}

.Select-menu-outer {
  z-index: 100;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .275);
  font-size: 14px;
}

.Select.Select--up {
  .Select-arrow {
    transform: rotate(180deg);
    margin-top: -5px;
  }
  .Select-menu-outer {
    top: auto;
    
    box-shadow: 0 -6px 12px rgba(0, 0, 0, .275);
  }
}
