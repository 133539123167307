.table {
 & tr > td {
   &:nth-child(3) {
    text-align: right;
  }
    &:nth-child(7) {
      vertical-align: middle;
    }
  }
}

.clickable {
  cursor: pointer;
}

.datetime {
  margin-bottom: 0;
}

.label {
  font-size: 11px;
  white-space: nowrap;
  margin-bottom: 4px;
  display: block;
}

.note {
  font-size: 11px;
}
