.row {
  border-bottom: 1px solid #ccc;
  padding: 7px 0;
}

.channels {
  margin-right: 5px;
  float: left;
}

.createdAt {
  color: #666;
  float: right;
}

.title {
  clear: both;
  padding-top: 2px;
  font-weight: bold;
}

.summary {
  clear: both;
  padding-top: 2px;
  color: #666;
  font-size: 12px;
}

.link, .link:hover {
  text-decoration: none;
  color: #333;
}

.deleted {
  color: #ef3b28;
}

.emailHeaders {
  padding: 1em;
  background: #DDD;
}
.emailBody {
  padding: 1em;
}

.right {
  text-align: right;
}

.attachment {
  text-decoration: none !important;
}

.emailModal > div {
  max-width: 90%;
}

.emailModal > div > div {
  max-height: inherit !important;
}
