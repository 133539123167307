.table {
  & td.rowLink {
    padding: 0 !important;

    & a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 8px;
      color: #000;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.settingsHeader {
  font-weight: bold;
  font-size: 16;
}

.selectAgent {
  min-width: 200px;
}

.accountIcon {
  width: 20px;
  height: 20px;
  border-radius: 2px;

  & + & {
    margin-left: 10px;
  }
}

.label {
  position: relative;
  top: -2px;
}

.addClientBanner {
  position: fixed;
  top: 50px;
  bottom: 0;
  right: 0;
  transition: all 0.2s ease-in-out;
  transform: translateX(100%);
  width: 500px;
  background-color: #fff;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  visibility: hidden;
  opacity: 0;
}

.addClientOpen {
  transition: all 0.2s ease-in-out;
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
  overflow: auto;
}

.hideOverflow {
  overflow: hidden;
  position: relative;
}

.addLead {
  padding: 15px;
  margin-bottom: 10px;
}

.addLeadHeader {
  background-color: #f3f3f3;
  margin: -15px;
  padding: 15px 20px;
  margin-bottom: 10px;
  font-size: 18px;
  color: #ed1c24;
  text-transform: uppercase;
  font-weight: 700;
}

.addStrategicPartnersHeader {
  background-color: #f3f3f3;
  padding: 15px 20px;
  margin: 10px -15px;
  font-size: 18px;
  color: #ed1c24;
  text-transform: uppercase;
  font-weight: 700;
}

.eyeIcon {
  font-size: 15px;
  color: #777;
  margin-left: 10px;
  cursor: pointer;
}

.addLeadIcon {
  font-size: 30px;
  float: right;
  color: #b9b9b9;
}

.rangeSlider {
  input[type='range'] {
    /*removes default webkit styles*/
    -webkit-appearance: none;

    /*fix for FF unable to apply focus style bug */
    border: 1px solid white;
    cursor: pointer;
    box-shadow: none;
  }
  input[type='range']::-webkit-slider-runnable-track {
    height: 5px;
    background: #eee;
    border: none;
    border-radius: 3px;
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #d2d2d2;
    margin-top: -4px;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']:focus::-webkit-slider-runnable-track {
    background: #eee;
  }

  input[type='range']::-moz-range-track {
    height: 5px;
    background: #eee;
    border: none;
    border-radius: 3px;
  }
  input[type='range']::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #d2d2d2;
  }

  /*hide the outline behind the border*/
  input[type='range']:-moz-focusring {
    outline: 1px solid #aaa;
    outline-offset: -1px;
  }

  input[type='range']::-ms-track {
    height: 5px;

    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;

    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;

    /*remove default tick marks*/
    color: transparent;
  }
  input[type='range']::-ms-fill-lower {
    background: #eee;
    border-radius: 10px;
  }
  input[type='range']::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
  }
  input[type='range']::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #d2d2d2;
  }
  input[type='range']:focus::-ms-fill-lower {
    background: #eee;
  }
  input[type='range']:focus::-ms-fill-upper {
    background: #ccc;
  }
}

.showOnlineControl {
  position: absolute;
  top: 10;
}
.toggleSwitch {
  display: none;

  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .toggleButton {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .toggleButton {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .toggleButton:after {
    left: 50%;
  }
}

.toggleLight {
  + .toggleButton {
    background: #f0f0f0;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
    &:after {
      border-radius: 50%;
      background: #fff;
      transition: all 0.2s ease;
    }
  }

  &:checked + .toggleButton {
    background: #9fd6ae;
  }
}

.advanceSettingIconBar {
  margin: auto;
  padding: 5px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: 5px;
  i {
    font-size: 24px;
  }
  &:hover {
    background: darken(#f6f6f6, 1);
    transition: all 0.4s ease;
  }
  transition: all 0.4s ease;
}

.advanceSettingDivider {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}

.advanceSettings {
  background: #fff;
  margin: 0 !important;
  padding: 15px;
  border-radius: 10px;
}

.onlineStatusLabel {
  height: 10px;
  width: 10px;
  line-height: 10px;
  margin: 0 5px;
  vertical-align: middle;
  border-radius: 50%;
  display: inline-block;
  color: #fff;
}

.editableField {
  padding: 8px;
  border-radius: 5px;
  height: 100%;
}

.editableField:hover,
.editableField:focus {
  background: #fff;
}

.editableFieldError {
  background: #ef9898;
}

.generalClientNoteBtnCol {
  padding-top: 10px;
  padding-left: 0 !important;
  button {
    width: 100px;
    height: 52px;
    font-size: 15px;
  }
}

.nonEditableField {
  width: 100%;
  height: 100%;
  padding: 8px;
  display: inline-block;
}

.blueIcon {
  color: #00aced;
  font-size: 16;
}

.ibFilters {
  text-align: center;
  font-weight: bold;
}

.strategicPartnersUpload {
  margin-top: 20px;
  label {
    float: left;
    input[type='file'] {
      display: none;
      margin-bottom: 0;
    }
  }
}

.numberNoArrows {
  margin: 0;
  -moz-appearance: textfield;
}

.numberNoArrows::-webkit-outer-spin-button,
.numberNoArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.dropdownSearch > button {
  border-radius: 4px 0 0 4px;
}

.pushNotificationModalContent > div > div {
  width: fit-content;
}

.buttonsContainer {
  gap: 1rem;
}
