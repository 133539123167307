.table {
  max-height: calc(100% - 195px);
  overflow-y: auto;
  font-size: 14px;

  & td {
    padding: 5px !important;
    vertical-align: middle !important;
    & a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 3px;
      color: #000;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    & div {
      display: block;
      padding: 8px;
    }
  }
}

.clickable {
  cursor: pointer;
}

.amountCell {
  text-align: right;
}
.showFilter{
  margin-top: 10px !important;
}

.datetime {
  margin-bottom: 0;
}

.label {
  font-size: 11px;
  white-space: nowrap;
  margin-bottom: 4px;
  display: block;
}
