
.paymentOptionItem {
  dt {
    width: 200px!important;
  }
  dd {
    margin-left: 210px!important;
  }
  margin-bottom: 10px;
}

.countryOption {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px !important;
  color: #000;
}

.table {
  & td {
    padding: 5px !important;
    white-space: nowrap;

    & a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 8px;
      color: #000;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
    & div {
      display: block;
      padding: 8px;
    }
  }
  td.countryOption {
    display: block;
    width: 100%;
    height: 100%;
    padding: 8px !important;
    color: #000;
  }
  th.largeColumn {
    min-width: 380px;
  }
}

.glyphicon {
  float: right;
  margin-right: 5px;
  color: white;
  background-color: black;
  padding: 3px;
  border-radius: 2px;
}

.formLink {
  display: block;
  height: 22px;
}

.formStyle {
  min-height: 55%
}
#new-countryPaymentOption{
  padding-top: 10px !important;
}
#t-edit-country-payment-option{
  margin-top: 5px !important;
}

.clickable {
  cursor: pointer;

  & + & {
    margin-left: 6px;
  }
}

.controlButton {
  margin: 10px;
}

.controlButtonGroup {
  margin-top: 100px;
}

.selectedPaymentOption {
  background-color: #ccc;
  border-left: 2px solid #DB4437;
}

.paymentOptionLogo {
  width: 100%;
  height: auto;
}

.companySelect {
  margin-bottom: 15px;
}
