.userIcon {
  width: 24px;
  height: 24px;
  border-radius: 2px;

  & + & {
    margin-left: 10px;
  }
}

.log, .meta {
  display: flex;
  align-items: center;
}
.log {
  justify-content: space-between;
  margin: 1em 0;
  border-left: 5px solid #ddd;
  .message {
    width: 66%;
    padding: .7em;
    font-size: 13px;
    .postpone {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .meta {
    width: 33%;
    font-size: 11px;
    /*
    .user-image {
      height: 40px;
      width: 40px;
    } */
    .userAndTime {
      padding: 0 .7em
    }
  }
}