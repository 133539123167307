.icon {
  padding-right: 12px;
}

.main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.button {
  background-color: #DB4437 !important;
  color: #FFF !important;
}


.emailSignInButton {
  background-color: #3498db;
  color: #fff;
}

.globalSettingsTrigger {
  cursor: pointer;
}

.globalSettings {
  max-width: 600px;
  width: 600px;
}

.tripleButtons {
  width: 100%;

  & button {
    width: 33.33333333%;
  }
}

.doubleButtons {
  width: 100%;

  & button {
    width: 50%;
  }
}

.loginContainer, .resetPasswordContainer {
  background: #fff;
  min-height: 500px;
  border-radius: 10px;
}

.resetPasswordContainer {
  min-height: 300px;
}

.loginError {
  margin: 5px;
  font-size: 12px;
  color: #DB4437;
}

.centerContainer {
  height: 100%;
  display: flex;
  align-items: center;
  width: 80%;
}

.loginHeader {
  margin-top: 10px;
  & span {
    font-size: 24px;
    font-weight: bold;
    display: block
  }
}

.loginIcon {
  font-size: 24px;
}

.textCenter {
  text-align: center;
}

.loginOr {
  position: relative;
  color: #aaa;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
   & span {
    display: block;
    position: absolute;
    left: 50%;
    top: -2px;
    margin-left: -25px;
    background-color: #fff;
    width: 50px;
    text-align: center;
   }
   & hr {
    height: 1px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
   }
  }

:global .floating-modal {
  position: fixed !important;
  pointer-events: none;
  display: contents !important;

  .modal-dialog {
    pointer-events: all;
    width: 400px;
    position: fixed;
    bottom: 0;
    right: 20px;
  }

  .modal-header {
    cursor: pointer;
    user-select: none;
  }
}

.menuNav {
  height: 45px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}

.logo {
  width: auto;
  max-width: 120px;
  max-height: 45px;
}

.logoutButton {
  margin-top: 20px;
  margin-bottom: 20px;
}

.avatar {
  margin-right: 4px;
}

@media (max-width: 1399px) {
  .menuNav > div {
    width: 1200px;
  }
  .menuNav .userName {
    display: none;
  }
}

.menuNav > div > div > a {
  margin-left: 7px;
  margin-right: 7px;
}

.menuNav a {
  color: #777;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
}

.sidebarContainer {
  position: fixed;
  top: 45px;
  bottom: 0;
  right: 0;
  transition: all .2s ease-in-out;
  transform: translateX(100%);
  width: 600px;
  background-color: #fff;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  visibility:hidden;
  opacity:0;
  padding: 15px;
  margin-bottom: 10px;
}

.showSidebar {
  transition: all .2s ease-in-out;
  transform: translateX(0);
  visibility: visible;
  opacity:1;
}

.overlay {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: none;
}

.toggleSwitch {
	display: none;

	// add default box-sizing for this scope
	&,
  &:after,
  &:before,
	& *,
  & *:after,
  & *:before,
	& + .toggleButton {
		box-sizing: border-box;
		&::selection {
			background: none;
		}
	}

	+ .toggleButton {
		outline: 0;
		display: block;
		width: 4em;
		height: 2em;
		position: relative;
		cursor: pointer;
    user-select: none;
		&:after,
    &:before {
			position: relative;
			display: block;
			content: "";
			width: 50%;
			height: 100%;
		}

		&:after {
			left: 0;
		}

		&:before {
			display: none;
		}
	}

	&:checked + .toggleButton:after {
		left: 50%;
	}
}

.toggleLight {
	+ .toggleButton {
		background: #e6e6e6;
		border-radius: 2em;
		padding: 2px;
		transition: all .4s ease;
		&:after {
			border-radius: 50%;
			background: #fff;
			transition: all .2s ease;
		}
	}

	&:checked + .toggleButton {
		background: #2fb836;
  }

	&:disabled + .toggleButton {
    opacity: 0.5;
    cursor: not-allowed;
	}
}

.sectionTitle {
  margin-top: 3px;
  margin-bottom: 3px;
  padding-top: 7px;
  text-align: center;
}
