.table {
  max-height: calc(100% - 195px);
  overflow-y: auto;
  font-size: 14px;
}
.table {
  & td, & th {
    padding: 8px;

    & a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 8px;
      color: #000;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
    & div {
      display: block;
      padding: 8px;
    }
  }
}

.label {
  font-size: 11px;
  white-space: nowrap;
  margin-bottom: 4px;
  display: block;
}

.warningIcon {
  color: #d63939;
}

.successIcon {
  color:green;
}