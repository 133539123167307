.container {
  padding-bottom: 24px;
}

.table {
  max-height: calc(100% - 195px);
  overflow-y: auto;
  font-size: 14px;
  margin-bottom: 0;

  & .transactions-commissions-table__tbody tr {
    cursor: pointer;
  }

  & th {
    & :first-child {
      padding-right: 10px;
    }

    & :last-child {
      cursor: pointer;
      width: auto;
    }
  }

  & td {
    padding: 8px;
    vertical-align: middle !important;
  }
}

.filter {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.datetime {
  margin-bottom: 0;
}

.label {
  font-size: 11px;
  white-space: nowrap;
  margin-bottom: 4px;
  display: block;
}

.subTitle {
  padding-top: 10px;
}
