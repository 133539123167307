.eidMatchMesageContainer{
  background:  rgb(241, 239, 239);
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  h5 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;
  }
  dt {
    margin-bottom: 5px;
  }
  dd {
    margin-left: 5px;
  }
}

.modal > div > div {
  max-height: inherit !important;
}
