.dormantFeeForm{
  input[type="file"] {
    display: none;
  }
  input[type="checkbox"]
  {
    transform: scale(2);
    padding: 10px;
  }
  .checkboxLabel{
    font-size: 110%;
    display: inline;
  }
  div[role="toolbar"]{
    margin: 20px 0
  }
}

.addJobContainer {
  position: fixed;
  top: 50px;
  bottom: 0;
  right: 0;
  transition: all .2s ease-in-out;
  transform: translateX(100%);
  width: 500px;
  background-color: #fff;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  visibility:hidden;
  opacity:0;
}

.addJobFormOpen {
  transition: all .2s ease-in-out;
  transform: translateX(0);
  visibility: visible;
  opacity:1;
}

.hideOverflow
{
  overflow: hidden;
  position:relative;
}

.addJobContainer {
  padding: 15px;
  margin-bottom: 10px;
}

.addJobHeader {
  background-color: #f3f3f3;
  margin: -15px;
  padding: 15px 20px;
  margin-bottom: 10px;
  font-size: 18px;
  color: #ed1c24;
  text-transform: uppercase;
  font-weight: 700;
}

.addJobIcon {
  font-size: 30px;
  float:right
}
