.breadcrumb {
  margin-top: 0;
}

.topBar {
  float: right;
  margin: 0 0 0.5rem -15px !important;

  .topBarButton {
    display: inline-block;
    margin-left: 5px;

    :global .btn {
      font-size: 12px;
    }
  }
}

.generalInfo {
  margin-bottom: 10px;
  display: flex;
}

.flexContainer {
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.flexGrow {
  flex-grow: 1;
}

.contactToolbar {
  margin: 12px -5px 15px -15px !important;
  clear: both;
}

.dates {
  clear: both;
  width: 250px !important;
}

.datesdd {
  margin-left: 170px !important;
}

.datesdt {
  width: 150px !important;
}

.selectLabel {
  font-weight: bold;
}

.selectAction {
  font-weight: normal;
}

.actionButton {
  margin-bottom: 15px !important;
  min-height: 34px;
}

.kycContainer {
  display: block;
  padding: 15px;
  background-color: #f5f5f5;
  margin: -15px;
  margin-bottom: 10px;
}

.kycInfo {
  margin-left: 10px;
  font-size: 13px;
  color: gray;
}

.account,
.vpsSubscription,
.personalDetails {
  padding-bottom: 15px;
  padding-top: 15px;

  // &:first-child {
  //   padding-top: 0;
  // }

  // &:last-child {
  //   padding-bottom: 0;
  // }
}

.account {
  dt, dd {
    margin-bottom: 5px;
  }
}

.personalDetails > div {
  margin-bottom: 1.25rem;
}

.noteInputs {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;

  input[type="file"] {
    display: none;
    margin-bottom: 0;
  }
}

.noEvents {
  padding: 15px 0;
  text-align: center;
}

.event {
  background-color: white;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  border-left: 10px solid;
  font-size: 0.8rem;
  line-height: 1.2;
}

.expandableEvent {
  background-color: white;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  border-left: 10px solid;
  font-size: 0.8rem;
  line-height: 1.2;
  height: 82px;
  overflow: hidden;
  transition: height .3s ease-in-out;
  position: relative;
}
.expandableEvent.alternateRow {
  background-color: f6f6f6;
}
.expandableEvent .moreButton {
  position: absolute;
  width: 100%;
  background: linear-gradient(to bottom, transparent 0%, #fff 75%);
  bottom: 0;
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
  border-bottom: 1px solid #ccc;
  text-decoration: none;
  color: #666;
}
.expandableEvent.alternateRow .moreButton {
  background: linear-gradient(to bottom, transparent 0%, #f6f6f6 75%);
}

// Event Types
.appointment {
  border-left: solid 2px rgba(40, 40, 200, .4);
}

.activityLog {
  border-left: solid 2px rgba(200, 40, 40, .4);
}

.note {
  border-left: solid 2px rgba(40, 200, 40, .4);
}

// Event Origins
.origin-client {
  border-left: solid 2px rgba(200, 40, 200, .4);
}

.outgoingCall {
  border-left: solid 2px rgb(21, 175, 60);
}

.playIcon {
  margin: 0px 0 0 6px;
  position: relative;
  top: 1px;
  color: #000;
}

.rejectBonusDeposit,
.rejectWithdrawal,
.rejectDocument {
  // every activity log type has its own class, the class name is identical with enum name in activityLogTypes
}

.noteText {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: .25em 0;
  font-size: .9rem;
}

.noteLink {
  margin-top: 5px;
}

.avatar {
  height: 40px;
  width: 40px;
  margin-right: 10px;
  border-radius: 4px;
  float: left;
}

.clientIcon {
  width: 40px;
  font-size: 40px;
  text-align: center;
}

.content {
  margin-left: 50px;
}

.clickable {
  cursor: pointer;

  & + & {
    margin-left: 6px;
  }
}

.deposits,
.withdrawals {
  & td {
    vertical-align: middle;
  }
  & tr > td {
    vertical-align: middle!important;
    &:first-child {
      text-align: right;
    }
    &:nth-child(6),
    &:nth-child(7) {
      vertical-align: middle;
    }
  }
}

.documents {
  & tr > * {
    &:nth-child(1) {
      width: 15%;
    }

    &:nth-child(2) {
      width: 15%;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4) {
      width: 15%;
    }

    &:nth-child(5) {
      width: 11%;
    }

    &:nth-child(4), &:nth-child(5) {
      vertical-align: middle
    }
  }
}

.profileChanges {
  & tr > * {
    &:nth-child(1) {
      width: 10%;
    }

    &:nth-child(2) {
      width: 15%;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4) {
      width: 15%;
    }

    &:nth-child(3), &:nth-child(4) {
      vertical-align: middle
    }
  }
}

.appropTests {
  & tr > * {
    &:nth-child(1) {
      width: 10%;
    }

    &:nth-child(2) {
      width: 15%;
    }

    &:nth-child(3) {
      width: 12%;
    }

    &:nth-child(4) {
      width: 12%;
    }

    &:nth-child(6), &:nth-child(7) {
      vertical-align: middle
    }
  }
}

.spotOptionPositions {
  font-size: 12px;

  & tr > td {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(6),
    &:nth-child(8) {
      text-align: right;
    }
  }
}

.spotOptionPositions th, .spotOptionPositions td {
  padding: 8px;
}

.forexPositions {
  font-size: 11px;

  & tr > td {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(6),
    &:nth-child(8) {
      text-align: right;
    }
  }
}

.forexPositions th, .forexPositions td {
  padding: 8px;
}

.cuboidPositions {
  font-size: 12px;

  & tr > td {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(9) {
      text-align: right;
    }
  }
}

.cuboidPositions th, .cuboidPositions td {
  padding: 8px;
}

.toolbar {
  margin-bottom: 10px;
  height: 34px;
}

.tradingStatusWarning {
  margin-bottom: 15px;
}

.futureEvent + .pastEvent {
  border-top: 1px solid #ddd;
  margin-top: 10px;
}

.gray {
  color: gray;
}

.title {
  margin: 0;
  margin-bottom: .25em;
  font-weight: bold;
  font-size: 1.2em;
}

.passwordInfo, .generateCode {
  margin-top: 10px;

  code {
    font-size: 100%;
  }
}

.formLink {
  display: block;
  height: 22px;
}

.showMore {
  cursor: pointer;
}

.showMoreEvents {
  cursor: pointer;
  text-align: center;
  display: block;
}

.aptAnswer {
  white-space: nowrap;
  overflow: hidden;
}

.aptAnswerChanged {
  composes: aptAnswer;
  background-color: #cea !important;
}

.aptAnswerUnchanged {
  composes: aptAnswer;
  background-color: #eee !important;
}

.aptAlert {
  composes: aptAnswer;
  background-color: red !important;
}

.ip {
  color: #777;
}

.eddAnswerTextfield {
  white-space: pre-line;
  height: auto;
  width: 100%;
  height: auto;
  padding: 6px 12px;
  background-color: #eee;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.eddAnswerChangedTextfield {
  white-space: pre-line;
  height: auto;
  width: 100%;
  padding: 6px 12px;
  background-image: none;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  background-color: #d4edda !important;
}

.generalClientNoteTextAreaEditable:hover, .generalClientNoteTextAreaEditable:focus {
  border: 1px solid #ccc;
  border-radius: 5px;
  background: white;
  margin-bottom: 0px;
}

.generalClientNoteTextArea {
  width: 100%;
  background: transparent;
  border: 1px solid #cccccc;
  border-radius: 3px;
  resize: none;
  padding: 5px;
  outline: none;
}

.withdrawalClientNoteTextArea {
  font-weight: 700;
}

.generalClientNoteBtnCol {
  padding-top: 10px;
  padding-left:0!important;
  button {
    width: 100px;
    height: 52px;
    font-size: 15px;
  }
}

.searchBox {
  border-radius: 20px;
}

.editDeleteAccountLink {
  margin-left: 10px;
  cursor: pointer;
}

.fixedSidePanel {
  position: fixed;
  min-width: 328px;
  max-width: 506px;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
}

.resolvedAlert {
  border-left: solid 2px rgb(21, 175, 60);
}

.activeAlert {
  border-left: solid 2px rgb(255, 0, 0);
}

.postponedAlert {
  border-left: solid 2px rgb(240, 240, 0);
}

.resolveButton{
  display: none;
}

.attended {
  background-color: rgb(21, 175, 60) !important;
}

.notAttended {
  background-color: rgb(255, 0, 0) !important;
}

.onlineStatusLabel, .vipIcon {
  height: 20px;
  width: 20px;
  line-height: 20px;
  margin: 0 5px;
  vertical-align: middle;
  border-radius: 50%;
  display: inline-block;
  color: #fff;
}
.deleteAccount {
  text-align: center;
  vertical-align: middle;
  background: #f03b28;
  color: #fff;
  font-size: 12;
  width: 48px;
  padding: 4px;
  border-radius: 10px;
  font-weight: 900;
}
.companyIcon {
  color: white;
  background: #f03b28;
}

.blueIcon{
  color:#00aced;
  font-size: 16;
  padding-left: 5px;
}

.butttonGroup {
  white-space: nowrap;
  button {
    margin: 2px;
  }
}
.toggleSwitch {
	display: none;

	// add default box-sizing for this scope
	&,
  &:after,
  &:before,
	& *,
  & *:after,
  & *:before,
	& + .toggleButton {
		box-sizing: border-box;
		&::selection {
			background: none;
		}
	}

	+ .toggleButton {
		outline: 0;
		display: block;
		width: 4em;
		height: 2em;
		position: relative;
		cursor: pointer;
    user-select: none;
		&:after,
    &:before {
			position: relative;
			display: block;
			content: "";
			width: 50%;
			height: 100%;
		}

		&:after {
			left: 0;
		}

		&:before {
			display: none;
		}
	}

	&:checked + .toggleButton:after {
		left: 50%;
	}
}

.toggleLight {
	+ .toggleButton {
		background: #e6e6e6;
		border-radius: 2em;
		padding: 2px;
		transition: all .4s ease;
		&:after {
			border-radius: 50%;
			background: #fff;
			transition: all .2s ease;
		}
	}

	&:checked + .toggleButton {
		background: #2fb836;
  }

	&:disabled + .toggleButton {
    opacity: 0.5;
    cursor: not-allowed;
	}
}

.collapsePostpone,
.collapseComment {
  margin-top: .5em;
}

.fieldSet {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 10px;
}

.link::after {
  content: ", "
}

.link:last-child::after {
  content: ""
}

.callDuration {
  text-align: right;
  margin-bottom: 5px;
}

.readOnlyDealer {
  background: #ecff1f;
}

.rawRow {
  border: 1px solid #ccc;
  margin: 25px 0px 0px !important;
  border-radius: 4px;
  padding: 10px;
}
.rawTitle{
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
}

.rawSelect{
  padding: 0 !important;
}

.dividerDiv {
  margin-top: 15px;
}

.dtSubscripton {
  white-space: pre-line !important;
}

.toolbarBtns {
  margin-bottom: 5px;
}

.indented {
  padding-left: 2rem !important;
}

.kycApproved {
  background: #2fb836;
}

.kycRejected {
  background: #f03b28;
}

.kycPending {
  background: #777
}

.kycApTestPending {
  background: #00aced
}

.tradingTools{
  margin-top: 10px;
  border: 2px solid #000;
}

.greenText {
  color: #2fb836
}

.orangeText {
  color: #ec971f
}

.redText {
  color: #f03b28
}

.updateCopyTrading{
  margin-top: -8px;
}

.warning {
  background: #f0ad4e
}

.multipleCallingCodes {
  font-size: 10px;
  text-align: right;
  padding-bottom: 15px;
}

.datetime {
  margin-bottom: 0;
}

.label {
  font-size: 11px;
  white-space: nowrap;
  margin-bottom: 4px;
  display: block;
}

.appendixFormField {
  font-size: 12px;
}

.appointmentColor {
  &.btn-primary {
    background-color: #337ab7;
    border-color: #337ab7;
    &:active {
      background-color: #337ab7;
    }
    &:active,
    &:focus,
    &:active:focus {
      outline: none;
      background-color: #2e6da4;
    }
    &:hover,
    &[disabled]:hover{
      background-color: #2e6da4;
      border-color: #2e6da4;
    }
  }
}

.appointmentToolbar {
  float: right !important;
  .completeCheckbox {
    margin-left: 10px;
  }
}

.providing {
  background-color: #E8F7FE !important;
  color: #297dd8 !important;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 5px;
}

.redText {
  color: #f03b28;
}

.centeredText {
  text-align: center;
}

.allowNewAccounts {
  margin-left: 0px !important;
}

.allowManuallyAccounts {
  padding-top: 15px;
  margin-bottom: -10px;
}

.moreActions > button, .moreActions > button:hover {
  color: #ef3b28;
}

.accountCollapseButton {
  margin-top: -20px;
}

.strikethrough {
  text-decoration: line-through;
}

.eventTitle {
  font-size: .9rem;
}

.eventUserCol {
  padding-left: 10px;
  padding-right: 5px;
  flex: 0 0 13%;
  max-width: 13%;
}

.eventMainCol {
  padding-left: 5px;
  padding-right: 10px;
  flex: 0 0 87%;
  max-width: 87%;
}

.phoneVerificationInvalidPhone{
  border-left: 2px solid #FF9F00;
}

.diffPlus {
  background-color: #28a7453b;
}

.diffMinus {
  background-color: #dd34442b;
}

.overflowFixedSidePanel {
  position: fixed;
  min-width: 328px;
  max-width: 506px;
  max-height: calc(100vh - 70px);
}

.eyeIcon {
  font-size: 18px;
  color:#777;
  margin-left: 10px;
  cursor: pointer;
}

.outerRow {
  border-top: 1px solid #ddd;
}

.expandColumn {
  border-right: 1px solid #ddd;
  text-align: center;
}

.sectionTitle {
  margin-top: auto;
  margin-bottom: auto;
  text-transform: uppercase;
  text-orientation: upright;
  writing-mode: vertical-lr;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sectionTitleForHidden {
  text-transform: uppercase;
  margin: 5px;
}

.hiddenSection {
  display: none;
}

.totalsIcon {
  font-size: 22px;
}

.expandableIcon {
  margin-top: 6px;
}

.quickActionCol {
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.quickActionHeader {
  border-bottom: 1px solid #ddd;
  font-size: 17px;
  align-items: center;
  display: flex;
}

.styledInput {
  font-size: 14px;
  color: hsl(0, 0%, 20%);
  &::placeholder {
    color: hsl(0, 0%, 50%);
  }
  &:disabled,
  &[disabled] {
    color: hsl(0, 0%, 60%);
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
    &::placeholder {
      color: hsl(0, 0%, 60%);
    }
  }
  &:focus {
    border-width: 2px;
    border-color: #2684ff;
  }
}

.affiliateButtonWrap {
  display: flex;
  justify-content: center;
}

