.gap4 {
  gap: 1.5rem;
}

.fs5 {
  font-size: 1.25rem;
}

.pointer {
  cursor: pointer;
}

.copySection {
  border: 2px dashed #007bff;
  background-color: rgba(#007bff, 0.1);
}

.modalContent {
  max-width: 750px;
}

.whiteLabelsPicker {
  width: 300px;
}
