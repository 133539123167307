.table {
  max-height: calc(100% - 195px);
  overflow-y: auto;
  font-size: 14px;
}
.table {
  & td {
    padding: 0 !important;

    & a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 8px;
      color: #000;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
    & div {
      display: block;
      padding: 8px;
    }
    & button {
      margin: 8px;
      display: inline-block;
    }
  }
}

.toolbar {
  margin-bottom: 15px;
}

.form {
  margin-top: 31px;
  label {
    font-weight: bold;
  }
  textarea {
    min-height: 200px;
  }
}

.panel-body {
  overflow-y: none;
}

.tableIbs {
  width: 30%,
}


