body {
  padding-top: 60px;
  background-color: #f6f6f6;
  font-size: 'Helvetica Neue,Helvetica,Arial,sans-serif';
  font-size: 14px;
}
a {
  color: #ef3b28;
}
a:hover {
  color: #cf1b08;
}

small, .small {
  font-size: 80%;
}

.btn:hover {
  opacity: 0.75;
}

.btn-outline-secondary,.btn-outline-secondary:disabled {
  background-color: #fff;
}

.btn-default:active:focus, .btn-default.active:focus {
  background-color: transparent;
}
.btn:active,
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn.active:focus,
.btn.active:active,
.btn.active:active:focus {
  box-shadow: none;
  outline: none;
}
.btn.btn-default.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}
.btn-default.active:focus {
  background-color: #e6e6e6;
}
.btn-default:focus {
  background-color: white;
}
//make button look like disabled. This is needed because really disabled dont send mouseleave/mouseenter events
//thus preventing tooltip
.btn-default.fakeDisabled {
  opacity: 0.5;
  cursor: default;
}


.btn-primary:active:focus {
  box-shadow: none;
  // background: red !important;
}
.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open>.dropdown-toggle.btn-primary.focus, .open>.dropdown-toggle.btn-primary:focus, .open>.dropdown-toggle.btn-primary:hover
{
  background-color: #267e1b;
  border-color: #267e1b;
}
.btn-primary {
  background-color: #469e3b;
  border-color: #469e3b;
  &:active {
    background-color: #469e3b;
  }
  &:active,
  &:focus,
  &:active:focus {
    outline: none;
    background-color: #267e1b;
  }
  &:hover,
  &[disabled]:hover{
    background-color: #267e1b;
    border-color: #267e1b;
  }
  //make button look like disabled. This is needed because really disabled dont send mouseleave/mouseenter events
  //thus preventing tooltip
  &.fakeDisabled {
    opacity: 0.5;
    cursor: default;
  }
}

.list-group-item:focus,
.list-group-item:active {
  outline: none;
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: #f5f5f5;
  border-color: #ddd;
  color: #555;
}

.navbar-brand {
  padding: 5px;
}
.panel {
  margin-bottom: 10px;
}
.panel-group .panel-heading {
  border-bottom: 1px solid #ddd;
}
.panel-body {
  max-height: calc(100% - 135px);
  overflow-y: auto;
}
.overrideOverflow{
  .panel-body {
    max-height: none;
    overflow-y: visible;
  }
}
.panel-max-size {
  .panel-body {
    max-height: calc(100% - 300px);
  }
}
.panel-events {
  .panel-body {
    padding: 0 15px;
    min-height: 485px;
  }
}
.panel-short {
  .panel-body {
    overflow-y: initial;
  }
}

.form-control:focus {
  box-shadow: none;
}

.has-error .form-control:focus,
.has-success .form-control:focus {
  box-shadow: none;
}

.has-error > div > input {
  border-color: #dc3545;
}

.has-success .form-control {
  background-color: #e6fedb;
}

.container {
  width: 1170px;
  min-width: 1170px;
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 1170px;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1370px;
  }
}

@media (min-width: 1500px) {
  .container {
    width: 1470px;
  }
}

@media (min-width: 1600px) {
  .container {
    width: 1570px;
  }
}


.navbar-collapse.collapse {
  display: block!important;
}

.navbar {
  margin-bottom: 10px;
}

.navbar-nav>li,
.navbar-nav {
  float: left !important;
  margin: 0;
}

.navbar-nav.navbar-right:last-child {
  margin-right: -15px !important;
}

.navbar-right {
  float: right!important;
}

.container > .navbar-header {
  margin-right: 0;
  margin-left: 0;
  float: left;
}

@media (max-width: 1170px) {
  .navbar-fixed-top {
    width: 1170px;
  }
}

.nav > li > a {
  padding: 10px 14px;
}

.navbar-nav > li > a,
.navbar-nav > .dropdown > ul > li > a {
  font-weight: bold;
  text-transform: uppercase;
}

.navbar-nav > li > a {
  padding-top: 15px;
  padding-bottom: 11px;
}

.navbar-nav > .dropdown > ul > li > a {
  color: #777;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover,
  &:focus {
    color: #333;
    background: none;
    outline: none;
  }
}

.navbar-nav > .active > span {
  color: #ef3b28;
  background: none;
}

.navbar-nav > div > .dropdown-menu > .dropdown-item.active,
.navbar-nav > div > .dropdown-menu > .dropdown-item:active {
  color: inherit;
  background-color: inherit;
}

.dropdown-item-active {
  color: #ef3b28 !important;
  background-color: white !important;
}

.navbar-nav > .active {
  color: #ef3b28 !important;
  border-bottom: 4px solid #ef3b28;
}

.navbar-nav > .active:hover,
.navbar-nav > .active:focus {
  background: none;
  color: #ef3b28;
  outline: none;
}

.tab-content {
  background-color: white;
  border: 1px solid #ddd;
  border-top: 0;
  border-radius: 0 0 3px 3px;
  padding: 15px;
  min-height: 65px;
}

.close-tab {
  float: right !important;
  cursor: default;

  a {
    margin-top: 3px;
    border: 0 !important;
    cursor: pointer !important;
    padding: 0 !important;

    &:hover {
      background: none !important;
      border: 0 !important;
      color: #555 !important;
    }
  }

  span {
    padding: 10px 15px;
  }
}

.tabs-without-border .tab-content {
  border: 0;
}

.nav-tabs > a {
  color: #888;
}

.nav-tabs > a:hover {
  border: 1px solid #ddd;
  border-bottom: 0;
  color: #555;
}

.nav > a:focus {
  outline: none;
}

.nav-stacked > li {
  margin-top: 5px;
  & > a {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.dl-horizontal dt {
  float: left;
  width: 100px;
  overflow: hidden;
  clear: left;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 10px;
}

.dl-horizontal dd {
  margin-left: 120px;
  margin-bottom: 0;
  word-wrap: break-word;
  margin-bottom: 10px;
}

.dl-horizontal.long-title dt {
  width: 160px;
}

.dl-horizontal.long-title dd {
  margin-left: 180px;
}

.dl-horizontal.very-long-title dt {
  width: 200px;
}

.dl-horizontal.very-long-title dd {
  margin-left: 220px;
}

dt {
  margin-bottom: .1em;
}
dd {
  margin-bottom: .5em;
}

dl {
  margin-bottom: 0;
}

.list-group {
  margin-bottom: 0;
}

.list-group-item:first-child {
  border-top: 0;
}
.list-group-item {
  padding-left: 0;
  padding-right: 0;
  border-left: 0;
  border-right: 0;
}

.pagination > li > a,
.pagination > li > a:focus,
.pagination > li > a:active:focus,
.pagination > li > span {
  color: #ef3b28;
}
.list-unstyled {
  margin-bottom: 0;
}

.breadcrumb {
  padding-left: 0;
  background-color: transparent;
}

.breadcrumb > li + li:before {
  content: "\00a0/\00a0";
}

.pagination > li > a:focus {
  outline: none;
}

.pagination > li > a:hover {
  color: #333;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ef3b28;
  border-color: #ef3b28;
}

.pagination > .active > a,
.pagination > .active > a:hover {
  background-color: #ef3b28;
  border-color: #ef3b28;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #ef3b28;
  border-color: #ef3b28;
}

/* Style react-select */

.Select-option:hover {
  background-color: #f2f9fc;
	color: #333;
}
.well {
  background-color: white;
}

.Select-transparent .Select-control {
  background-color: transparent;
}

table {
  font-size: 14px;
}

.largePanel .panel-body {
  max-height: initial;
}

.no-filter {
  .panel-body {
    max-height: calc(100% - 80px);
  }
}

.two-rows-filter {
  .panel-body {
    max-height: calc(100% - 190px);
  }
}

.clickable {
  cursor: pointer;
}

.clientsTableCheckbox {
  input[type="checkbox"] {
    margin-top: -10px;
    margin-left: -5px;
  }
}

.clientsTableHeaderCheckbox {
  input[type="checkbox"] {
    margin-top: -16px;
  }
}

.rejectionReasonTextArea {
  border-style: none;
}

a[disabled] {
  cursor: default;
  color: #555;
  text-decoration: none;
}

.badge {
  vertical-align: top;
}

.label {
  border-radius: 10px;
}

.withdrawalEditorNoActivityLog {
  .tab-content {
    max-height: calc(100% - 135px);
    overflow: auto;
  }
}

.noPadding {
  padding: 0;
}

.btn .form-group {
  margin-bottom: 0 ;
}

.tab-content .panel {
  border: 0;
  margin: -15px;
  padding-top: 20px;
}

.sidebar-col > .nav > .nav-link {
  padding: 8px;
}

.bonusBackground{
  background-color: rgba(246, 195, 66, 0.15);
  &:hover{
    background-color:darken(rgba(246, 195, 66, 0.15), 15%)  !important;
  }
}

.accuracyAccountsWhiteLabel, .accuracyAccountsMauritiusWhiteLabel {
  background-color: #8ee2fc;

  &:hover {
    background-color: darken(#8ee2fc, 10%) !important;
  }
}

.regalxWhiteLabel, .regalxMauritiusWhiteLabel , .regalxVanuatuWhiteLabel  {
  background-color: rgba(71, 133, 186, 0.8);

  &:hover {
    background-color: darken(rgba(71, 133, 186, 0.8), 10%) !important;
  }
}

.bdsTradingWhiteLabel, .bdsTradingMauritiusWhiteLabel, .bdsTradingVanuatuWhiteLabel {
  background-color: #f5c69a;

  &:hover {
    background-color: darken(#f5c69a, 10%) !important;
  }
}

.vip {
  background-color: #f7eab0;

  &:hover {
    background-color: darken(#f7eab0, 10%) !important;
  }
}

.vip-active {
  background-color: darken(#f7eab0, 10%) !important;
}

.bs-callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
}
.bs-callout h4 {
    margin-top: 0;
    margin-bottom: 5px;
}
.bs-callout p:last-child {
    margin-bottom: 0;
}
.bs-callout code {
    border-radius: 3px;
}
.bs-callout+.bs-callout {
    margin-top: -5px;
}
.bs-callout-default {
    border-left-color: #777;
}
.bs-callout-default h4 {
    color: #777;
}
.bs-callout-primary {
    border-left-color: #428bca;
}
.bs-callout-primary h4 {
    color: #428bca;
}
.bs-callout-success {
    border-left-color: #5cb85c;
}
.bs-callout-success h4 {
    color: #5cb85c;
}
.bs-callout-danger {
    border-left-color: #d9534f;
}
.bs-callout-danger h4 {
    color: #d9534f;
}
.bs-callout-warning {
    border-left-color: #f0ad4e;
}
.bs-callout-warning h4 {
    color: #f0ad4e;
}
.bs-callout-info {
    border-left-color: #5bc0de;
}
.bs-callout-info h4 {
    color: #5bc0de;
}

.client-online {
  background: #5cb85c;
}

.client-offline {
  background: #cacaca
}

.modal-lg {
  width: 80%;
}

.open-positions {
  background-color: #dc3545;

  &:hover {
    background-color: darken(#dc3545, 10%) !important;
  }
  a {
    color: white !important;
  }
}

.label-info {
  background-color: #5bc0de;
}

.label {
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}


.container {
  max-width: 100%;
}

.fit-to-page {
  max-height: calc(100vh - 135px);
  overflow-y: auto;
}

.modal-content {
  max-height: calc(100% - 135px);
}

.btn-xs, .btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

table > tbody > tr.active {
  background-color: #f5f5f5;
}

.appointment-tomato > button {
  background-color: #d50000;
  border-color: #d50000;

  &:hover,&:focus {
    background-color: #d50000;
    border-color: #d50000;
    color: #fff;
    opacity: 0.75;
  }
}
.appointment-tomato > button:active {
  background-color: #d50000 !important;
  border-color: #d50000 !important;
  color: #fff !important;
}

.appointment-flamingo > button {
  background-color: #e67c73;
  border-color: #e67c73;

  &:hover,&:focus {
    background-color: #e67c73;
    border-color: #e67c73;
    color: #fff;
    opacity: 0.75;
  }
}
.appointment-flamingo > button:active {
  background-color: #e67c73 !important;
  border-color: #e67c73 !important;
  color: #fff !important;
}

.appointment-tangerine > button {
  background-color: #f4511e;
  border-color: #f4511e;

  &:hover,&:focus {
    background-color: #f4511e;
    border-color: #f4511e;
    color: #fff;
    opacity: 0.75;
  }
}
.appointment-tangerine > button:active {
  background-color: #f4511e !important;
  border-color: #f4511e !important;
  color: #fff !important;
}

.appointment-banana > button {
  background-color: #f6bf26;
  border-color: #f6bf26;

  &:hover,&:focus {
    background-color: #f6bf26;
    border-color: #f6bf26;
    color: #fff;
    opacity: 0.75;
  }
}
.appointment-banana > button:active {
  background-color: #f6bf26 !important;
  border-color: #f6bf26 !important;
  color: #fff !important;
}

.appointment-sage > button {
  background-color: #33b679;
  border-color: #33b679;

  &:hover,&:focus {
    background-color: #33b679;
    border-color: #33b679;
    color: #fff;
    opacity: 0.75;
  }
}
.appointment-sage > button:active {
  background-color: #33b679 !important;
  border-color: #33b679 !important;
  color: #fff !important;
}

.appointment-basil > button {
  background-color: #0b8043;
  border-color: #0b8043;

  &:hover,&:focus {
    background-color: #0b8043;
    border-color: #0b8043;
    color: #fff;
    opacity: 0.75;
  }
}
.appointment-basil > button:active {
  background-color: #0b8043 !important;
  border-color: #0b8043 !important;
  color: #fff !important;
}

.appointment-peacock > button {
  background-color: #039be5;
  border-color: #039be5;

  &:hover,&:focus {
    background-color: #039be5;
    border-color: #039be5;
    color: #fff;
    opacity: 0.75;
  }
}
.appointment-peacock > button:active {
  background-color: #039be5 !important;
  border-color: #039be5 !important;
  color: #fff !important;
}

.appointment-blueberry > button {
  background-color: #3f51b5;
  border-color: #3f51b5;

  &:hover,&:focus {
    background-color: #3f51b5;
    border-color: #3f51b5;
    color: #fff;
    opacity: 0.75;
  }
}
.appointment-blueberry > button:active {
  background-color: #3f51b5 !important;
  border-color: #3f51b5 !important;
  color: #fff !important;
}

.appointment-lavender > button {
  background-color: #7986cb;
  border-color: #7986cb;

  &:hover,&:focus {
    background-color: #7986cb;
    border-color: #7986cb;
    color: #fff;
    opacity: 0.75;
  }
}
.appointment-lavender > button:active {
  background-color: #7986cb !important;
  border-color: #7986cb !important;
  color: #fff !important;
}

.appointment-grape > button {
  background-color: #8e24aa;
  border-color: #8e24aa;

  &:hover,&:focus {
    background-color: #8e24aa;
    border-color: #8e24aa;
    color: #fff;
    opacity: 0.75;
  }
}
.appointment-grape > button:active {
  background-color: #8e24aa !important;
  border-color: #8e24aa !important;
  color: #fff !important;
}

.appointment-graphite > button {
  background-color: #616161;
  border-color: #616161;

  &:hover,&:focus,&:active {
    background-color: #616161;
    border-color: #616161;
    color: #fff;
    opacity: 0.75;
  }
}
.appointment-graphite > button:active {
  background-color: #616161 !important;
  border-color: #616161 !important;
  color: #fff !important;
}

input:disabled, button:disabled {
  cursor: not-allowed;
}

.dl-horizontal.space-wrap dt, dt.dt-horizontal.space-wrap {
  white-space: inherit;
}

.dl-horizontal.space-wrap dd, dd.dd-horizontal.space-wrap {
  padding-top: 11px;
  padding-bottom: 10px;
}

@media (min-width: 576px) {
  #t-payment-option-modal {
    max-width: 650px;
  }
}

.clientDetailsTabContainer + .tab-content {
  padding-top: 0 !important;
}