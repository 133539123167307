.table {
  & td {
    padding: 0 !important;
    white-space: nowrap;

    & a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 8px;
      color: #000;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
    & div {
      display: block;
      padding: 8px;
    }
  }
  th.largeColumn {
    min-width: 380px;
  }
}

.glyphicon {
  float: right;
  margin-right: 5px;
  color: white;
  background-color: black;
  padding: 3px;
  border-radius: 2px;
}

.formLink {
  display: block;
  height: 22px;
}

.formStyle {
  min-height: 0;
}

.addCreateTag {
  position: fixed;
  top: 50px;
  bottom: 0;
  right: 0;
  transition: all .2s ease-in-out;
  transform: translateX(100%);
  width: 500px;
  background-color: #fff;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  visibility:hidden;
  opacity:0;
}

.addTagOpen {
  transition: all .2s ease-in-out;
  transform: translateX(0);
  visibility: visible;
  opacity:1;
}

.addTag {
  padding: 15px;
  margin-bottom: 10px;
}

.addTagHeader {
  background-color: #f3f3f3;
  margin: -15px;
  padding: 15px 20px;
  margin-bottom: 10px;
  font-size: 18px;
  color: #ed1c24;
  text-transform: uppercase;
  font-weight: 700;
}

.addTagIcon {
  font-size: 30px;
  float:right;
  color:#b9b9b9;
}