.toolbar, .buttonToolbar {
  margin-bottom: 15px;
}

.mb10 {
  margin-bottom: 10px;
}

.table {
  & td:not(.externalLink) {
    padding: 0 !important;

    & a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 8px;
      color: #000;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
  & tr > td {
    &:nth-child(7) {
      vertical-align: middle;
    }
  }
}

.label {
  font-size: 11px;
  white-space: nowrap;
  margin-bottom: 4px;
  display: block;
}

.tradingStatus {
  margin-bottom: 10px;
}

.assignee{
  min-width: 200px;
}

.inlineBlock {
  display: inline-block;
}