.table {
  max-height: calc(100% - 195px);
  overflow-y: auto;
  font-size: 14px;
}

.table {
  & td {
    padding: 0 !important;

    & a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 8px;
      color: #000;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
    & div {
      display: block;
      padding: 8px;
    }
  }
}

.form :global(.checkbox) {
  margin-top: 31px;
  label {
    font-weight: bold;
  }
}

.newTradingAlertBtn {
  float: right;
  margin-bottom: 15px;
}

.toolbar {
  margin-bottom: 15px;
}

.actionbuttons {
  float: right;
  margin-right: 10px;
}

.hideEditor {
  position: fixed;
  top: 50px;
  bottom: 0;
  right: 0;
  transition: all .2s ease-in-out;
  transform: translateX(100%);
  width: 500px;
  background-color: #fff;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  visibility:hidden;
  opacity:0;
}

.showEditor {
  transition: all .2s ease-in-out;
  transform: translateX(0);
  visibility: visible;
  opacity:1;
}

.form {
  padding: 15px;
  margin-bottom: 10px;
}

.displayInline {
  display: inline
}