.table {
  max-height: calc(100% - 195px);
  overflow-y: auto;
  font-size: 14px;

  & tbody tr {
    cursor: pointer;
  }

  & td, & th {
    padding: 8px;

    & a {
      display: block;
      width: 100%;
      height: 100%;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
    & div {
      display: block;
      padding: 8px;
    }
    & button {
      margin: 8px;
      display: inline-block;
    }
  }
}

.toolbar {
  margin-bottom: 20px;
}
.label {
  font-size: 11px;
  white-space: nowrap;
  margin-bottom: 4px;
  display: block;
}
