.toolbar {
  margin-bottom: 15px;
  height: 34px;

  .form-group {
    margin-bottom: 0;
  }
}

.clearSearch {
  position: relative;
  right: 35px;
}

.avatar {
  height: 60px;
  float: left;
}
.largeAvatar {
  width: 100%;
  max-width: 84px;
  max-height: 84px;
}

.name {
  margin: 0;
  font-size: 1.3em;
}

.clickable {
  cursor: pointer;
}

.locationTitle {
  // background-color: #ef3b28;
  font-size: 1.3em;
  font-weight: 600;
  text-transform: uppercase;
}

.emptyDetails {
  margin-top: 49px;
}

.gray {
  color: gray;
}

.leadDistributionEditorListItem {
  background: #fff;
  padding: 10px;
  border-bottom: 1px solid #efefef;
  border-left: 1px solid #fff;
  cursor: pointer;
  &:hover {
    background: darken(#fbfbfb, 0.1);
    border-left: 1px solid #eee;
    }
  input[type="checkbox"] {
    margin-top: 12px;
    cursor: pointer;
  }
}

.leadDistributionEditorListTitle {
  font-size: 25px;
  font-weight: 550;
}

.leadDistributionEditorListSubtitle {
  font-size: 14px;
  color: #6d6d6d;
}

.leadDistributionEditorListSelected, .modifiedRow {
  border-left: 1px solid red;
  &:hover {
    background: darken(#fbfbfb, 0.1);
    border-left: 1px solid red;
    }
}

.removeIcon {
  cursor: pointer;
  font-size: 12px;
  margin-left: 5px;
  &:hover {
    color: darken(#ccc, 1)
  }
}

.removableLabel {
  margin-right: 5px;
  display: inline-block !important;
}

.campaignsBody {
  margin-bottom: 10px;
}

.modalHeader {
  font-weight: bold;
  font-size: 16
}

.smallLabel {
  font-size: 12px;
}

.documentDistributionEditorListItem {
  background: #fff;
  padding: 10px;
  border-bottom: 1px solid #efefef;
  border-left: 1px solid #fff;
  cursor: pointer;
  &:hover {
    background: darken(#fbfbfb, 0.1);
    border-left: 1px solid #eee;
    }
  input[type="checkbox"] {
    margin-top: 12px;
    cursor: pointer;
  }
}

.documentDistributionEditorListTitle {
  font-size: 20px;
  font-weight: 500;
}

.documentDistributionEditorListSubtitle {
  font-size: 16px;
  color: #6d6d6d;
}

.documentDistributionEditorListSelected, .modifiedRow {
  border-left: 4px solid green;
  &:hover {
    background: darken(#fbfbfb, 0.1);
    border-left: 4px solid green;
    }
}
.inputCheck{
  width: 20px !important;
}
.formStyle {
  min-height: 0;
}

.addCreateShift {
  position: fixed;
  top: 50px;
  bottom: 0;
  right: 0;
  transition: all .2s ease-in-out;
  transform: translateX(100%);
  width: 500px;
  background-color: #fff;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  visibility:hidden;
  opacity:0;
}

.addShiftOpen {
  transition: all .2s ease-in-out;
  transform: translateX(0);
  visibility: visible;
  opacity:1;
}

.addShift {
  padding: 15px;
  margin-bottom: 10px;
}

.addShiftHeader {
  background-color: #f3f3f3;
  margin: -15px;
  padding: 15px 20px;
  margin-bottom: 10px;
  font-size: 18px;
  color: #ed1c24;
  text-transform: uppercase;
  font-weight: 700;
}

.addShiftIcon {
  font-size: 30px;
  float:right;
  color:#b9b9b9;
}

.shiftPanel {
  min-height: 500px;
}

.shiftRecord {
  cursor: pointer;
}

.leadDistributionEditorSetButton {
  margin-top: 26px;
}
