.showMore {
  text-align: center;
  a {
    font-weight: normal;
    cursor: pointer;
  }
}

.toolbar {
  margin-top: 10px;
}
