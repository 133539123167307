.badge {
  color: white !important;
}

.editButton {
  margin-right: 15px;
}

.descriptionLabel {
  display: inline-block;
  margin-bottom: 3px;
}

.listItem {
  color: #777;
}

.badgeList {
  margin-top: 9px;
  font-size: 10px;
}

.scrollable {
  overflow: auto; 
  height: calc(100% - 220px);
}

.moreButton {
  text-align: center;
}

.mt10 {
  margin-top: 10px;
}

.dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 3px;
  top: 3px;
  position: relative;
}

.appointment {
  cursor: pointer;
}