.table {
  max-height: calc(100% - 195px);
  overflow-y: auto;
  font-size: 14px;
}
.table {
  & td {
    padding: 0 !important;

    & a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 8px;
      color: #000;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
    & div {
      display: block;
      padding: 8px;
    }
    & button {
      margin: 8px;
      display: inline-block;
    }
  }
}

.toolbar {
  margin-bottom: 15px;
}

.panel-body {
  overflow-y: none;
}

.csvInput {
  padding: 10px;
  display: block;
  margin: 15px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.table.seminarsExample {
  & td {
    text-align: center;
  }
  & th{
    text-align: center;
  }
}

.modal > div > div {
  min-width: 600px;
  overflow-y: visible;
  max-height: 100%;
}