.table {
  max-height: calc(100% - 195px);
  overflow-y: auto;
  font-size: 14px;

  & td {
    padding: 5px !important;
    vertical-align: middle !important;
    & a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 3px;
      color: #000;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    & div {
      display: block;
      padding: 8px;
    }
  }
}

.clickable {
  cursor: pointer;
}

.toolbar, .buttonToolbar {
  margin-bottom: 15px;
}

.amountCell {
  text-align: right;
}
.showFilter{
  margin-top: 10px !important;
}

.datetime {
  margin-bottom: 0;
}

.label {
  font-size: 11px;
  white-space: nowrap;
  margin-bottom: 4px;
  display: block;
}


.postponePast {
  background-color: #ff7675;

}

.postponeApproaching {
  background-color: #ffeaa7;
}

.moreFiltersIconBar {
  margin: auto;
  padding: 5px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: 5px;
  i {
    font-size: 24px;
  }
  &:hover {
    background: darken(#f6f6f6, 1);
    transition: all 2s ease;
  }
  transition: all 2s ease;
}

.moreFilters {
  background: #fff;
  margin: 0 !important;
  padding: 15px;
  border-radius: 10px;
}
