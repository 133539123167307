.table {
  max-height: calc(100% - 195px);
  overflow-y: auto;
  font-size: 14px;
}
.table {
  & td {
    padding: 0 !important;

    & a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 8px;
      color: #000;
      &:hover, &:focus {
        text-decoration: none;
      }
    }

    & a.leaderboardButton {
      max-width: 90%;
      margin: 5px auto 0;
      padding: 0.25rem 0.5rem;
    }

    & div {
      display: block;
      padding: 8px;
    }
  }

  & td {
    padding: 0 !important;
    & span {
      & a {
        display: inline;
        color: #ef3b28 !important;
        &:hover, &:focus {
          text-decoration: none;
        }
      }
    }
  }
}

.toolbar {
  margin-bottom: 15px;
}

.form > .panel-body {
  height: 100%;
}

.leaderboardHeader {
  margin-bottom: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.leaderboardHeaderInfo p {
  margin: 0;
}

.leaderboardHeaderInfo span {
  font-weight: bold;
}

.leaderboardHeaderExport button {
  max-width: 250px;
}
