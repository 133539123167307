.Roles-header {
  justify-content: space-between;
  padding: 0 15px;
}

.Roles-actions {
  display: flex;

  button {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}