.table {
  & td {
    padding: 0 !important;
    white-space: nowrap;

    & a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 8px;
      color: #000;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
    & div {
      display: block;
      padding: 8px;
    }
  }
  th.largeColumn {
    min-width: 380px;
  }
}

.bonusSettingIconBar {
  margin: auto;
  padding: 5px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: 5px;
  i {
    margin-left: 7px;
    font-size: 16px;
  }
  transition: all 0.4s ease;
}

.minDepositInfo {
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: bold;
  color: gray;
  text-align: center;
}

.accountIcon {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-left: 10px;
  float: right;
  margin-top: 1px;
}

.glyphicon {
  float: right;
  margin-right: 5px;
  color: white;
  background-color: black;
  padding: 3px;
  border-radius: 2px;
}
.checkboxInput{
  margin-top: 35px;
}

input[type="checkbox"] {
  transform: scale(1.5);
}

    
.toggleSwitch {
  display: none;

  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .toggleButton {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .toggleButton {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .toggleButton:after {
    left: 50%;
  }
}


.toggleLight {
  + .toggleButton {
    background: #e6e6e6;
    border-radius: 2em;
    padding: 2px;
    transition: all .4s ease;
    &:after {
      border-radius: 50%;
      background: #fff;
      transition: all .2s ease;
    }
  }

  &:checked + .toggleButton {
    background: #2fb836;
  }

  &:disabled + .toggleButton {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.row {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
}